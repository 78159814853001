import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Table, ListGroup, Modal } from 'react-bootstrap';
import { ref, push, remove, onValue, set } from "firebase/database";
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { auth, db } from '../firebase';
import { useNavigate } from 'react-router-dom';

function Admin() {
  const [newUser, setNewUser] = useState({ 
    email: '', 
    password: '', 
    firstName: '',
    lastName: '',
    isAdmin: false 
  });
  const [users, setUsers] = useState([]);
  const [newClient, setNewClient] = useState('');
  const [clients, setClients] = useState([]);
  const navigate = useNavigate();

  // State for confirmation modal
  const [showClientModal, setShowClientModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  useEffect(() => {
    const usersRef = ref(db, 'users');
    const clientsRef = ref(db, 'clients');

    const unsubscribeUsers = onValue(usersRef, (snapshot) => {
      const data = snapshot.val();
      const userList = data ? Object.entries(data).map(([id, user]) => ({ id, ...user })) : [];
      setUsers(userList);
    });

    const unsubscribeClients = onValue(clientsRef, (snapshot) => {
      const data = snapshot.val();
      const clientList = data ? Object.entries(data).map(([id, name]) => ({ id, name })) : [];
      setClients(clientList);
    });

    return () => {
      unsubscribeUsers();
      unsubscribeClients();
    };
  }, []);

  const handleAddUser = async (e) => {
    e.preventDefault();
    try {
      // Create the user
      const userCredential = await createUserWithEmailAndPassword(auth, newUser.email, newUser.password);
      const user = userCredential.user;

      // Send email verification
      await sendEmailVerification(user);

      // Save user data to Realtime Database
      await set(ref(db, 'users/' + user.uid), {
        email: newUser.email,
        firstName: newUser.firstName,
        lastName: newUser.lastName,
        isAdmin: newUser.isAdmin
      });

      setNewUser({ email: '', password: '', firstName: '', lastName: '', isAdmin: false });
      alert(`User created successfully. A verification email has been sent to ${newUser.email}.`);
    } catch (error) {
      console.error("Error adding user: ", error);
      alert('Failed to add user: ' + error.message);
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      await remove(ref(db, `users/${userId}`));
    } catch (error) {
      console.error("Error deleting user: ", error);
      alert('Failed to delete user: ' + error.message);
    }
  };

  const handleAddClient = async (e) => {
    e.preventDefault();
    if (newClient) {
      try {
        const newClientRef = push(ref(db, 'clients'));
        await set(newClientRef, newClient);
        setNewClient('');
      } catch (error) {
        console.error("Error adding client: ", error);
        alert('Failed to add client: ' + error.message);
      }
    }
  };

  const handleDeleteClient = async (clientId) => {
    setItemToDelete(clientId);
    setShowClientModal(true);
  };

  const confirmDeleteClient = async () => {
    try {
      await remove(ref(db, `clients/${itemToDelete}`));
      setShowClientModal(false);
    } catch (error) {
      console.error("Error deleting client: ", error);
      alert('Failed to delete client: ' + error.message);
    }
  };

  const handleBackToHome = () => {
    navigate('/progress-note');
  };

  return (
    <Container className="mt-4">
      <Row className="mb-4">
        <Col>
          <h2>Admin Area</h2>
        </Col>
        <Col xs="auto">
          <Button variant="secondary" onClick={handleBackToHome}>Back to Home</Button>
        </Col>
      </Row>
      
      <Row className="mb-5">
        <Col md={6}>
          <h3>User Management</h3>
          <Form onSubmit={handleAddUser}>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={newUser.email}
                onChange={(e) => setNewUser({...newUser, email: e.target.value})}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                value={newUser.password}
                onChange={(e) => setNewUser({...newUser, password: e.target.value})}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                value={newUser.firstName}
                onChange={(e) => setNewUser({...newUser, firstName: e.target.value})}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                value={newUser.lastName}
                onChange={(e) => setNewUser({...newUser, lastName: e.target.value})}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Check
                type="checkbox"
                label="Admin privileges"
                checked={newUser.isAdmin}
                onChange={(e) => setNewUser({...newUser, isAdmin: e.target.checked})}
              />
            </Form.Group>
            <Button type="submit">Add User</Button>
          </Form>
          <Table className="mt-3">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Admin</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id}>
                  <td>{`${user.firstName} ${user.lastName}`}</td>
                  <td>{user.email}</td>
                  <td>{user.isAdmin ? 'Yes' : 'No'}</td>
                  <td>
                    <Button variant="danger" size="sm" onClick={() => handleDeleteUser(user.id)}>
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
        
        <Col md={6}>
          <h3>Client Management</h3>
          <Form onSubmit={handleAddClient}>
            <Form.Group className="mb-3">
              <Form.Label>Client Name</Form.Label>
              <Form.Control
                type="text"
                value={newClient}
                onChange={(e) => setNewClient(e.target.value)}
                required
              />
            </Form.Group>
            <Button type="submit">Add Client</Button>
          </Form>
          <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
            <ListGroup className="mt-3">
              {clients.map((client) => (
                <ListGroup.Item key={client.id} className="d-flex justify-content-between align-items-center">
                  {client.name}
                  <Button variant="danger" size="sm" onClick={() => handleDeleteClient(client.id)}>
                    Delete
                  </Button>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </div>
        </Col>
      </Row>

      {/* Confirmation Modal for Client Deletion */}
      <Modal show={showClientModal} onHide={() => setShowClientModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this client?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowClientModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDeleteClient}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default Admin;