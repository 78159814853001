import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Link } from 'react-router-dom';
import { Button, Container, Nav, Navbar } from 'react-bootstrap';
import { onAuthStateChanged, signOut } from "firebase/auth";
import { ref, get } from "firebase/database";
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './components/Login';
import ProgressNote from './components/ProgressNote';
import Admin from './components/Admin';
import ReportViewer from './components/ReportViewer';
import { auth, db } from './firebase';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setIsLoggedIn(true);
        const userRef = ref(db, 'users/' + user.uid);
        try {
          const snapshot = await get(userRef);
          const userData = snapshot.val();
          setIsAdmin(userData && userData.isAdmin === true);
        } catch (error) {
          console.error("Error fetching user data:", error);
          setIsAdmin(false);
        }
      } else {
        setIsLoggedIn(false);
        setIsAdmin(false);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setIsLoggedIn(false);
      setIsAdmin(false);
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className="App">
        {isLoggedIn && (
          <Navbar bg="light" expand="lg">
            <Container>
              <Navbar.Brand as={Link} to="/progress-note">Support Track</Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Link as={Link} to="/progress-note">New Progress Note</Nav.Link>
                  <Nav.Link as={Link} to="/reports">View Reports</Nav.Link>
                  {isAdmin && (
                    <Nav.Link as={Link} to="/admin">Admin Area</Nav.Link>
                  )}
                </Nav>
                <Button variant="outline-primary" onClick={handleLogout}>Log Out</Button>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        )}
        <Container className="mt-4">
          <Routes>
            <Route path="/" element={
              isLoggedIn ? <Navigate to="/progress-note" replace /> : <Login setIsLoggedIn={setIsLoggedIn} setIsAdmin={setIsAdmin} />
            } />
            <Route path="/progress-note" element={
              isLoggedIn ? <ProgressNote /> : <Navigate to="/" replace />
            } />
            <Route path="/reports" element={
              isLoggedIn ? <ReportViewer /> : <Navigate to="/" replace />
            } />
            <Route path="/admin" element={
              isLoggedIn && isAdmin ? <Admin /> : <Navigate to="/" replace />
            } />
          </Routes>
        </Container>
      </div>
    </Router>
  );
}

export default App;