import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import { ref, push, set, get } from "firebase/database";
import { ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import { auth, db, storage } from '../firebase';
import { pdf } from '@react-pdf/renderer';
import ProgressNotePDF from './ProgressNotePDF';
import Select from 'react-select';

function ProgressNote() {
  const [formData, setFormData] = useState({
    supportWorkerName: '',
    date: new Date().toISOString().split('T')[0],
    time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
    clientName: '',
    progressNotes: '',
    medicationGiven: '',
    medicationChartSigned: '',
    medicationComments: '',
    behaviourDescription: '',
    incidentReported: '',
    incidentDescription: '',
    actionsTaken: '',
    additionalComments: ''
  });

  const [clients, setClients] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [reportNumber, setReportNumber] = useState(null);
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  // Function to format the report number
  const formatReportNumber = (num) => {
    return `GSLC${num.toString().padStart(5, '0')}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      const clientsRef = ref(db, 'clients');

      try {
        const clientsSnapshot = await get(clientsRef);

        if (clientsSnapshot.exists()) {
          const clientsData = clientsSnapshot.val();
          setClients(Object.entries(clientsData).map(([id, name]) => ({ value: name, label: name })));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setErrorMessage("Failed to load client data");
      }
    };

    fetchData();

    const user = auth.currentUser;
    if (user) {
      const userRef = ref(db, 'users/' + user.uid);
      get(userRef).then((snapshot) => {
        if (snapshot.exists()) {
          const userData = snapshot.val();
          setFormData(prevData => ({
            ...prevData,
            supportWorkerName: `${userData.firstName} ${userData.lastName}`
          }));
        }
      }).catch((error) => {
        console.error("Error fetching user data:", error);
      });
    }

    const reportNumberRef = ref(db, 'lastReportNumber');
    get(reportNumberRef).then((snapshot) => {
      if (snapshot.exists()) {
        setReportNumber(snapshot.val() + 1);
      } else {
        setReportNumber(1);
      }
    });

    // Listen for the beforeinstallprompt event
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      setDeferredPrompt(e); // Save the event
    });

  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleSelectChange = (selectedOption, actionMeta) => {
    setFormData(prevData => ({ 
      ...prevData, 
      [actionMeta.name]: selectedOption ? selectedOption.value : '' 
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccessMessage('');
    setErrorMessage('');

    try {
      const formattedReportNumber = formatReportNumber(reportNumber);

      const pdfDoc = <ProgressNotePDF data={formData} reportNumber={formattedReportNumber} />;
      const asPdf = pdf([]);
      asPdf.updateContainer(pdfDoc);
      const pdfBlob = await asPdf.toBlob();

      const pdfRef = storageRef(storage, `reports/${formattedReportNumber}.pdf`);
      await uploadBytes(pdfRef, pdfBlob);
      const pdfUrl = await getDownloadURL(pdfRef);

      const progressNotesRef = ref(db, 'progressNotes');
      const newNoteRef = push(progressNotesRef);
      await set(newNoteRef, {
        ...formData,
        reportNumber: formattedReportNumber,
        numericReportNumber: reportNumber,
        pdfUrl,
        timestamp: new Date().toISOString()
      });

      await set(ref(db, 'lastReportNumber'), reportNumber);

      const reader = new FileReader();
      reader.readAsDataURL(pdfBlob);
      reader.onloadend = async () => {
        const base64data = reader.result.split(',')[1];
        
        const response = await fetch('https://app1.progresstrack.com.au/send-email', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ pdfData: base64data, reportNumber: formattedReportNumber }),
        });

        if (!response.ok) {
          throw new Error('Failed to send email');
        }
      };

      setSuccessMessage(`Progress note saved and sent successfully! Report Number: ${formattedReportNumber}`);
      setReportNumber(prevNumber => prevNumber + 1);
      
      // Reset form fields
      setFormData(prevData => ({
        ...prevData,
        clientName: '',
        progressNotes: '',
        medicationGiven: '',
        medicationChartSigned: '',
        medicationComments: '',
        behaviourDescription: '',
        incidentReported: '',
        incidentDescription: '',
        actionsTaken: '',
        additionalComments: ''
      }));
    } catch (error) {
      console.error("Error saving progress note:", error);
      setErrorMessage('Failed to save progress note. Please try again.');
    }
  };

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
      });
    }
  };

  return (
    <Container>
      <h2 className="my-4">Progress Note</h2>
      {reportNumber && <Alert variant="info">Report Number: {formatReportNumber(reportNumber)}</Alert>}
      {successMessage && <Alert variant="success">{successMessage}</Alert>}
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Support Worker Name</Form.Label>
              <Form.Control
                type="text"
                name="supportWorkerName"
                value={formData.supportWorkerName}
                onChange={handleInputChange}
                disabled
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Date</Form.Label>
              <Form.Control
                type="date"
                name="date"
                value={formData.date}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Time</Form.Label>
              <Form.Control
                type="time"
                name="time"
                value={formData.time}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Form.Group className="mb-3">
          <Form.Label>Client Name</Form.Label>
          <Select
            options={clients}
            name="clientName"
            value={clients.find(client => client.value === formData.clientName) || null}
            onChange={handleSelectChange}
            isClearable
            isSearchable
            placeholder="Select or type to search..."
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Progress Notes</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="progressNotes"
            value={formData.progressNotes}
            onChange={handleInputChange}
          />
        </Form.Group>

        <Row>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Medication Given</Form.Label>
              <Form.Select
                name="medicationGiven"
                value={formData.medicationGiven}
                onChange={handleInputChange}
              >
                <option value="">Select...</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Medication Chart Signed</Form.Label>
              <Form.Select
                name="medicationChartSigned"
                value={formData.medicationChartSigned}
                onChange={handleInputChange}
              >
                <option value="">Select...</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        <Form.Group className="mb-3">
          <Form.Label>Medication Comments</Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            name="medicationComments"
            value={formData.medicationComments}
            onChange={handleInputChange}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Behaviour of Concern Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="behaviourDescription"
            value={formData.behaviourDescription}
            onChange={handleInputChange}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Any Incident Reported?</Form.Label>
          <Form.Select
            name="incidentReported"
            value={formData.incidentReported}
            onChange={handleInputChange}
          >
            <option value="">Select...</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </Form.Select>
        </Form.Group>

        {formData.incidentReported === 'Yes' && (
          <Form.Group className="mb-3">
            <Form.Label>Incident Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="incidentDescription"
              value={formData.incidentDescription}
              onChange={handleInputChange}
            />
          </Form.Group>
        )}

        <Form.Group className="mb-3">
          <Form.Label>Actions Taken</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="actionsTaken"
            value={formData.actionsTaken}
            onChange={handleInputChange}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Additional Comments</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="additionalComments"
            value={formData.additionalComments}
            onChange={handleInputChange}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Save Progress Note
        </Button>

        {deferredPrompt && (
          <Button variant="primary" onClick={handleInstallClick} className="mt-3">
            Install App
          </Button>
        )}

      </Form>
    </Container>
  );
}

export default ProgressNote;