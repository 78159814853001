import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    padding: 30,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  logo: {
    width: 100,
    height: 50,
  },
  headerInfo: {
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
    textAlign: 'center',
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginBottom: 10,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableColHeader: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: '#f0f0f0',
    padding: 5,
  },
  tableCol: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
  },
  tableCellHeader: {
    margin: 'auto',
    fontSize: 12,
    fontWeight: 'bold',
  },
  tableCell: {
    margin: 'auto',
    fontSize: 10,
  },
  section: {
    margin: 10,
    padding: 10,
  },
  sectionTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  text: {
    fontSize: 12,
    marginBottom: 5,
  },
});

// Function to format date
const formatDate = (dateString) => {
  const [year, month, day] = dateString.split('-');
  return `${day}-${month}-${year}`;
};

// Create Document Component
const ProgressNotePDF = ({ data, reportNumber }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <Image 
          style={styles.logo} 
          src="/images/logo.png"
        />
        <View style={styles.headerInfo}>
          <Text style={styles.text}>Report Number: {reportNumber}</Text>
          <Text style={styles.text}>Date: {formatDate(data.date)}</Text>
          <Text style={styles.text}>Time: {data.time}</Text>
        </View>
      </View>

      <Text style={styles.title}>Progress Note</Text>

      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Client Name</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{data.clientName}</Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Support Worker</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{data.supportWorkerName}</Text>
          </View>
        </View>
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Progress Notes</Text>
        <Text style={styles.text}>{data.progressNotes}</Text>
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Medication</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Medication Given</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{data.medicationGiven}</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Medication Chart Signed</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{data.medicationChartSigned}</Text>
            </View>
          </View>
        </View>
        <Text style={styles.text}>Comments: {data.medicationComments}</Text>
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Behaviour of Concern</Text>
        <Text style={styles.text}>{data.behaviourDescription}</Text>
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Incident Report</Text>
        <Text style={styles.text}>Any Incident Reported: {data.incidentReported}</Text>
        {data.incidentReported === 'Yes' && (
          <Text style={styles.text}>Incident Description: {data.incidentDescription}</Text>
        )}
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Actions Taken</Text>
        <Text style={styles.text}>{data.actionsTaken}</Text>
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Additional Comments</Text>
        <Text style={styles.text}>{data.additionalComments}</Text>
      </View>
    </Page>
  </Document>
);

export default ProgressNotePDF;