import React, { useState, useEffect } from 'react';
import { Container, Table, Button, Modal, Form, Pagination, Alert, ButtonGroup } from 'react-bootstrap';
import { ref, onValue, remove, get } from "firebase/database";
import { ref as storageRef, deleteObject, getDownloadURL } from "firebase/storage";
import { db, storage, auth } from '../firebase';
import ReactECharts from 'echarts-for-react';

function ReportViewer() {
  const [reports, setReports] = useState([]);
  const [selectedReport, setSelectedReport] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [reportsPerPage] = useState(10);
  const [message, setMessage] = useState({ text: '', type: '' });
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [emailStatus, setEmailStatus] = useState({ message: '', type: '' });
  const [chartData, setChartData] = useState([]);
  const [trendData, setTrendData] = useState([]);
  const [chartFilter, setChartFilter] = useState('day');
  const [showChart, setShowChart] = useState(true);

  useEffect(() => {
    const reportsRef = ref(db, 'progressNotes');
    const unsubscribe = onValue(reportsRef, (snapshot) => {
      const data = snapshot.val();
      const reportList = data ? Object.entries(data).map(([id, report]) => ({id, ...report})) : [];
      setReports(reportList.sort((a, b) => {
        return b.numericReportNumber - a.numericReportNumber;
      }));
      updateChartData(chartFilter, reportList);
    });

    return () => unsubscribe();
  }, [chartFilter]);

  const updateChartData = (filter, reportList) => {
    const currentDate = new Date();
    const oneMonthAgo = new Date(currentDate.getTime() - 30 * 24 * 60 * 60 * 1000);

    const filteredReports = reportList.filter(report => {
      const reportDate = new Date(report.date);
      switch (filter) {
        case 'day':
          return reportDate.toDateString() === currentDate.toDateString();
        case 'week':
          const oneWeekAgo = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
          return reportDate >= oneWeekAgo;
        case 'month':
          return reportDate.getMonth() === currentDate.getMonth() && reportDate.getFullYear() === currentDate.getFullYear();
        case 'year':
          return reportDate.getFullYear() === currentDate.getFullYear();
        default:
          return true;
      }
    });

    setChartData([{ value: filteredReports.length, name: filter }]);

    // Calculate trend data for the last 30 days
    const trend = Array(30).fill(0);
    reportList.forEach(report => {
      const reportDate = new Date(report.date);
      if (reportDate >= oneMonthAgo) {
        const dayIndex = 29 - Math.floor((currentDate - reportDate) / (24 * 60 * 60 * 1000));
        trend[dayIndex]++;
      }
    });
    setTrendData(trend);
  };

  const handleFilterChange = (filter) => {
    setChartFilter(filter);
    updateChartData(filter, reports);
  };

  const toggleChart = () => {
    setShowChart(!showChart);
  };

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${day}-${month}-${year}`;
  };

  const handleViewReport = (report) => {
    setSelectedReport(report);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedReport(null);
    setShowModal(false);
  };

  const sortedReports = React.useMemo(() => {
    let sortableReports = [...reports];
    if (sortConfig.key !== null) {
      sortableReports.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableReports;
  }, [reports, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleDeleteReport = async (report) => {
    if (window.confirm("Are you sure you want to delete this report?")) {
      try {
        const user = auth.currentUser;
        if (!user) throw new Error('User not authenticated');

        const isAdmin = await checkIfUserIsAdmin(user.uid);
        
        if (report.supportWorkerName !== user.email && !isAdmin) {
          throw new Error('Permission denied: You can only delete your own reports or you need admin privileges');
        }

        const dbRef = ref(db, `progressNotes/${report.id}`);
        await remove(dbRef);
        console.log("Report deleted from database");

        if (report.pdfUrl) {
          const pdfRef = storageRef(storage, report.pdfUrl);
          try {
            await deleteObject(pdfRef);
            console.log("Report PDF deleted from storage");
          } catch (storageError) {
            console.error("Error deleting from storage:", storageError);
            if (storageError.code === 'storage/object-not-found') {
              console.log("PDF file not found in storage, continuing with deletion");
            } else if (storageError.code === 'storage/unauthorized') {
              console.log("Unauthorized to delete PDF from storage, may require admin rights");
            } else {
              throw storageError;
            }
          }
        } else {
          console.log("No PDF URL found for this report");
        }

        setMessage({ text: 'Report deleted successfully', type: 'success' });
      } catch (error) {
        console.error("Error deleting report:", error);
        setMessage({ text: `Failed to delete report: ${error.message}`, type: 'danger' });
      }
    }
  };

  const handleDownloadReport = async (report) => {
    try {
      if (!report.pdfUrl) {
        throw new Error('No PDF URL found for this report');
      }
      
      const url = await getDownloadURL(storageRef(storage, report.pdfUrl));
      
      const link = document.createElement('a');
      link.href = url;
      link.download = `Report_${report.reportNumber}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setMessage({ text: 'Report download started', type: 'success' });
    } catch (error) {
      console.error("Error downloading report:", error);
      setMessage({ text: 'Failed to download report: ' + error.message, type: 'danger' });
    }
  };

  const handleEmailReport = async (report) => {
    try {
      if (!report.pdfUrl) {
        throw new Error('No PDF URL found for this report');
      }

      const response = await fetch('https://app1.progresstrack.com.au/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          pdfUrl: report.pdfUrl,
          reportNumber: report.reportNumber
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Failed to send email: ${errorData.message}`);
      }

      setEmailStatus({ message: 'Email sent successfully', type: 'success' });
    } catch (error) {
      console.error("Error emailing report:", error);
      setEmailStatus({ message: `Failed to email report: ${error.message}`, type: 'danger' });
    }
  };

  const checkIfUserIsAdmin = async (uid) => {
    const userRef = ref(db, `users/${uid}`);
    const snapshot = await get(userRef);
    return snapshot.val()?.isAdmin === true;
  };

  const filteredReports = sortedReports.filter(report => 
    report.clientName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    report.reportNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
    report.supportWorkerName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastReport = currentPage * reportsPerPage;
  const indexOfFirstReport = indexOfLastReport - reportsPerPage;
  const currentReports = filteredReports.slice(indexOfFirstReport, indexOfLastReport);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const formatTimeForDisplay = (time) => {
    if (!time) return '';
    const [timePart, ampm] = time.split(' ');
    const [hours, minutes] = timePart.split(':');
    let formattedHours = parseInt(hours);
    if (ampm === 'PM' && formattedHours !== 12) {
      formattedHours += 12;
    } else if (ampm === 'AM' && formattedHours === 12) {
      formattedHours = 0;
    }
    return `${formattedHours.toString().padStart(2, '0')}:${minutes}`;
  };

  return (
    <Container>
      <h2 className="my-4">Report Statistics</h2>
      <Button onClick={toggleChart} className="mb-3">
        {showChart ? 'Hide Chart' : 'Show Chart'}
      </Button>
      {showChart && (
        <>
          <ButtonGroup className="mb-3">
            <Button onClick={() => handleFilterChange('day')} active={chartFilter === 'day'}>Day</Button>
            <Button onClick={() => handleFilterChange('week')} active={chartFilter === 'week'}>Week</Button>
            <Button onClick={() => handleFilterChange('month')} active={chartFilter === 'month'}>Month</Button>
            <Button onClick={() => handleFilterChange('year')} active={chartFilter === 'year'}>Year</Button>
          </ButtonGroup>
          <div className="text-center mb-4">
            <h3>{chartFilter.charAt(0).toUpperCase() + chartFilter.slice(1)} Reports</h3>
            <h1 style={{fontSize: '4rem'}}>{chartData[0]?.value || 0}</h1>
          </div>
          <ReactECharts
            option={{
              title: {
                text: 'Report Trend (Last 30 Days)'
              },
              tooltip: {
                trigger: 'axis'
              },
              xAxis: {
                type: 'category',
                data: Array(30).fill(0).map((_, i) => {
                  const d = new Date();
                  d.setDate(d.getDate() - 29 + i);
                  return `${d.getDate()}/${d.getMonth() + 1}`;
                })
              },
              yAxis: {
                type: 'value'
              },
              series: [{
                data: trendData,
                type: 'line',
                smooth: true
              }]
            }}
            style={{ height: '400px', width: '100%' }}
          />
        </>
      )}

      <h2 className="my-4">Previous Reports</h2>
      {message.text && <Alert variant={message.type}>{message.text}</Alert>}
      {emailStatus.message && <Alert variant={emailStatus.type}>{emailStatus.message}</Alert>}
      <Form.Group className="mb-3">
        <Form.Control 
          type="text" 
          placeholder="Search by client name, report number, or support worker"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Form.Group>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th onClick={() => requestSort('reportNumber')} style={{cursor: 'pointer'}}>
              Report Number {sortConfig.key === 'reportNumber' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            <th onClick={() => requestSort('date')} style={{cursor: 'pointer'}}>
              Date {sortConfig.key === 'date' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            <th onClick={() => requestSort('time')} style={{cursor: 'pointer'}}>
              Time {sortConfig.key === 'time' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            <th onClick={() => requestSort('clientName')} style={{cursor: 'pointer'}}>
              Client Name {sortConfig.key === 'clientName' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            <th onClick={() => requestSort('supportWorkerName')} style={{cursor: 'pointer'}}>
              Support Worker {sortConfig.key === 'supportWorkerName' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentReports.map((report) => (
            <tr key={report.id}>
              <td>{report.reportNumber}</td>
              <td>{formatDate(report.date)}</td>
              <td>{formatTimeForDisplay(report.time)}</td>
              <td>{report.clientName}</td>
              <td>{report.supportWorkerName}</td>
              <td>
                <Button variant="primary" onClick={() => handleViewReport(report)} className="me-2">View</Button>
                <Button variant="success" onClick={() => handleDownloadReport(report)} className="me-2">Download</Button>
                <Button variant="info" onClick={() => handleEmailReport(report)} className="me-2">Email</Button>
                <Button variant="danger" onClick={() => handleDeleteReport(report)}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
  
      <Pagination>
        {[...Array(Math.ceil(filteredReports.length / reportsPerPage)).keys()].map(number => (
          <Pagination.Item key={number + 1} active={number + 1 === currentPage} onClick={() => paginate(number + 1)}>
            {number + 1}
          </Pagination.Item>
        ))}
      </Pagination>
  
      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Report #{selectedReport?.reportNumber}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedReport && (
            <>
              <p><strong>Date:</strong> {selectedReport.date}</p>
              <p><strong>Time:</strong> {selectedReport.time}</p>
              <p><strong>Client Name:</strong> {selectedReport.clientName}</p>
              <p><strong>Support Worker:</strong> {selectedReport.supportWorkerName}</p>
              <p><strong>Progress Notes:</strong> {selectedReport.progressNotes}</p>
              <p><strong>Medication Type:</strong> {selectedReport.medicationType}</p>
              <p><strong>Medication Time:</strong> {selectedReport.medicationTime}</p>
              <p><strong>Medication Comments:</strong> {selectedReport.medicationComments}</p>
              <p><strong>Behaviour Description:</strong> {selectedReport.behaviourDescription}</p>
              <p><strong>Incident Description:</strong> {selectedReport.incidentDescription}</p>
              <p><strong>Actions Taken:</strong> {selectedReport.actionsTaken}</p>
              <p><strong>Additional Comments:</strong> {selectedReport.additionalComments}</p>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default ReportViewer;