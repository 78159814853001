import React, { useState, useRef } from 'react';
import { Form, Button, Container, Row, Col, Image, Alert } from 'react-bootstrap';
import { signInWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail } from "firebase/auth";
import { ref, get } from "firebase/database";
import { auth, db } from '../firebase';
import { Link } from 'react-router-dom';
import HCaptcha from '@hcaptcha/react-hcaptcha';

function Login({ setIsLoggedIn, setIsAdmin }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [verificationSent, setVerificationSent] = useState(false);
  const [resetSent, setResetSent] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const captchaRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setVerificationSent(false);

    if (!captchaToken) {
      setError('Please complete the captcha');
      return;
    }

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      
      if (!user.emailVerified) {
        setError('Please verify your email before logging in.');
        await sendEmailVerification(user);
        setVerificationSent(true);
        return;
      }

      // Check if user is admin
      const userRef = ref(db, 'users/' + user.uid);
      const snapshot = await get(userRef);
      const userData = snapshot.val();
      
      setIsLoggedIn(true);
      setIsAdmin(userData && userData.isAdmin === true);

      console.log("User logged in:", user.email);
      console.log("Admin status:", userData && userData.isAdmin === true);

    } catch (error) {
      console.error("Error signing in: ", error);
      setError(error.message);
    } finally {
      // Reset captcha
      captchaRef.current.resetCaptcha();
      setCaptchaToken(null);
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    if (!email) {
      setError('Please enter your email address.');
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      setError('');
      setResetSent(true);
    } catch (error) {
      console.error("Error sending password reset email: ", error);
      setError(error.message);
    }
  };

  const onCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  return (
    <div className="d-flex flex-column min-vh-100">
      <Container className="flex-grow-1 d-flex flex-column justify-content-center">
        <Row className="justify-content-center mb-5">
          <Col xs={12} md={6} className="text-center">
            <Image src="/images/logo.png" alt="Logo" fluid />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={12} md={6}>
            <h2 className="text-center mb-4">Support Track Login</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            {verificationSent && <Alert variant="info">A new verification email has been sent. Please check your inbox.</Alert>}
            {resetSent && <Alert variant="success">Password reset email sent. Please check your inbox.</Alert>}
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </Form.Group>

              <div className="d-flex justify-content-center mb-3">
                <HCaptcha
                  sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY}
                  onVerify={onCaptchaChange}
                  ref={captchaRef}
                />
              </div>

              <Button variant="primary" type="submit" className="w-100">
                Login
              </Button>
            </Form>
            <div className="text-center mt-3">
              <Link to="#" onClick={handleForgotPassword}>
                Forgot Password?
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
      <footer className="mt-auto d-flex justify-content-center align-items-center" style={{ padding: '20px 0' }}>
        <Image src="/images/footer.png" alt="Footer" fluid style={{ maxWidth: '50%' }} />
      </footer>
    </div>
  );
}

export default Login;